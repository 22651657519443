import React from "react";

export default function GnomeSlayer() {
    let alive = localStorage.getItem("gnomes") === "true";



    return (
        // thanks copilot
        alive && (
            <button
                style={{
                    position: "fixed",
                    right: "20px",
                    bottom: "20px",
                    backgroundColor: "#ff4500",
                    color: "white",
                    border: "none",
                    borderRadius: "8px",
                    padding: "10px 20px",
                    fontSize: "16px",
                    fontWeight: "bold",
                    cursor: "pointer",
                    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                    transition: "transform 0.2s, box-shadow 0.2s",
                }}
                onMouseOver={(e) => {
                    e.target.style.transform = "scale(1.1)";
                    e.target.style.boxShadow = "0 6px 8px rgba(0, 0, 0, 0.2)";
                }}
                onMouseOut={(e) => {
                    e.target.style.transform = "scale(1)";
                    e.target.style.boxShadow = "0 4px 6px rgba(0, 0, 0, 0.1)";
                }}
                onClick={() => { localStorage.setItem("gnomes", "false"); window.location.reload() }}
            >
                Kill the Gnomes
            </button>
        )
    );
}

export function GnomeReviver() {
    let alive = localStorage.getItem("gnomes") === "true";

    return (
        // thanks copilot
        !alive && (
            <button
                style={{
                    position: "fixed",
                    right: "20px",
                    bottom: "20px",
                    backgroundColor: "#ff4500",
                    color: "white",
                    border: "none",
                    borderRadius: "8px",
                    padding: "10px 20px",
                    fontSize: "16px",
                    fontWeight: "bold",
                    cursor: "pointer",
                    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                    transition: "transform 0.2s, box-shadow 0.2s",
                }}
                onMouseOver={(e) => {
                    e.target.style.transform = "scale(1.1)";
                    e.target.style.boxShadow = "0 6px 8px rgba(0, 0, 0, 0.2)";
                }}
                onMouseOut={(e) => {
                    e.target.style.transform = "scale(1)";
                    e.target.style.boxShadow = "0 4px 6px rgba(0, 0, 0, 0.1)";
                }}
                onClick={() => { localStorage.setItem("gnomes", "true"); window.location.reload() }}
            >
                Revive the Gnomes
            </button>
        )
    );
}