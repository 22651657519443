import React, { useState } from "react";
import TwitchGlitchPurpleSVG from "../SVG/TwitchGlitchPurple.svg";
import { useQuery } from "@tanstack/react-query";
import getLivestreamers from "../queries/getLivestreamers";

export default function TwitchIntegration() {
  const [isOpen, setIsOpen] = useState(true);
  const { data, isError, isFetching } = useQuery({ queryKey: ["getLivestreamers"], queryFn: getLivestreamers, refetchInterval: 60000 });

  if ((isError || isFetching) && data?.length === 0) return <React.Fragment></React.Fragment>;

  if (data?.length === 0) return <React.Fragment></React.Fragment>;

  return (
    <div className="twitch-integration-wrapper">
      <div className="live-image-wrapper" onClick={() => setIsOpen(!isOpen)}>
        <img src={TwitchGlitchPurpleSVG} alt="Twitch Logo" className="twitch-logo" />
        {isOpen ? <p className="currently-live-text">Currently Live!</p> : <React.Fragment></React.Fragment>}
      </div>
      {isOpen ? data?.map((e) => <TwitchLiveTab key={e.user_name} streamer={e} />) : <React.Fragment></React.Fragment>}
    </div>
  );
}

export function TwitchLiveTab({ streamer }) {
  let url = `https://twitch.tv/${streamer.user_name}`;
  let thumbnail = "";
  if (streamer.thumbnail_url) {
    let size = 64;
    thumbnail = streamer.thumbnail_url.replace("{height}", size).replace("{width}", size);
  }

  return (
    <div className="twitch-integration-tab" onAuxClick={() => openInNewTab(url)} onClick={() => openInNewTab(url)}>
      {streamer.thumbnail_url ? <img src={thumbnail} alt="streamer thumbnail" className="streamer-thumbnail" /> : <React.Fragment></React.Fragment>}
      <div style={{ marginLeft: "auto", marginRight: "auto" }}>
        <div className="twitch-integration-name">{streamer.user_name}</div>
        <div>{streamer.viewer_count} Viewers</div>
      </div>
    </div>
  );
}

const openInNewTab = (url) => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};
