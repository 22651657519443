import { useRef, useEffect } from "react";

// /* Taken from Stack Overflow: https://stackoverflow.com/questions/21294302/converting-milliseconds-to-minutes-and-seconds-with-javascript*/
export function msConversion(millis) {
  let sec = Math.floor(millis / 1000);
  let hrs = Math.floor(sec / 3600);
  sec -= hrs * 3600;
  let min = Math.floor(sec / 60);
  sec -= min * 60;

  sec = "" + sec;
  sec = ("00" + sec).substring(sec.length);

  if (hrs > 0) {
    min = "" + min;
    min = ("00" + min).substring(min.length);
    return hrs + ":" + min + ":" + sec;
  } else {
    return min + ":" + sec;
  }
}

export function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

// https://stackoverflow.com/questions/563406/add-days-to-javascript-date
export function addDays(date, days) {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

export const guildRankColorizer = (rank, type) => {
  if (type === "realm") {
    if (rank === 1) return "legendary";
    if (rank === 2) return "epic";
    if (rank === 3) return "rare";
    if (rank > 3 && rank < 20) return "magic";
    return "grey";
  }

  if (type === "region") {
    if (rank < 20) return "legendary";
    if (rank < 50) return "epic";
    if (rank < 100) return "rare";
    if (rank < 200) return "magic";
    return "grey";
  }

  return "grey";
};



export function isInBlacklist(streamerName, blacklist) {
  if (blacklist.includes(streamerName)) {
    return true;
  } else return false;
}

// Boss health should be different colors based on the %
export function bossHealth({ fightPercentage, bossPercentage, name, addtlString }) {
  // I feel like I should keep this agnostic of the model but whatever
  let value = Number((fightPercentage / 100).toFixed(1)),
    rarity = "white";
  //the API returns the data as XXXX where num / 100 returns the %

  switch (name.toLowerCase()) {
    case "sylvanas windrunner":
      // sylvanas ends at 50% grr
      value = (bossPercentage / 100).toFixed(1);
      if (value <= 52.5) {
        rarity = "legendary";
      } else if (value <= 75) {
        rarity = "epic";
      } else if (value <= 84) {
        rarity = "rare";
      } else if (value <= 100) {
        rarity = "magic";
      }
      break;
    case "the jailer":
      value = (bossPercentage / 100).toFixed(1);
      if (value <= 5) {
        rarity = "legendary";
      } else if (value <= 20) {
        rarity = "epic";
      } else if (value <= 50) {
        rarity = "rare";
      } else if (value <= 75) {
        rarity = "magic";
      }
      break;
    case "raszageth the storm-eater":
      if (value <= 5) {
        rarity = "legendary";
      } else if (value <= 20) {
        rarity = "epic";
      } else if (value <= 40) {
        rarity = "rare";
      } else if (value <= 65) {
        rarity = "magic";
      }
      break;
    case "queen ansurek":
      value = Number(((bossPercentage || fightPercentage) / 100).toFixed(1));
      if (value <= 5) {
        rarity = "legendary";
      } else if (value <= 20) {
        rarity = "epic";
      } else if (value <= 50) {
        rarity = "rare";
      } else if (value <= 75) {
        rarity = "magic";
      }
      break;
    default:
      if (value <= 5) {
        rarity = "legendary";
      } else if (value <= 20) {
        rarity = "epic";
      } else if (value <= 50) {
        rarity = "rare";
      } else if (value <= 75) {
        rarity = "magic";
      }
      break;
  }

  return <b className={rarity}>{value}% {addtlString}</b>;
}


// function to take a name like Tindral Sageswift, Seer of the Flame, and convert it into Tindral Sageswift...
export function stripTitle(name) {
  return name.split(",")[0];
}
