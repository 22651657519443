import React from "react"

export default function ErrorBox({ text }) {



    return (<React.Fragment>
        <div className="error-box">
            <h1>{text}</h1>
        </div>
    </React.Fragment>)
}