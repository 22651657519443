// This probably doesn't work like this. You can fix this later if you care to do another april fools
// or other holiday related bg theme
export const AprilFools = () => {
  var ctx;
  var imgBg;
  var noOfDrops = 200;
  var fallingDrops = [];

  function drawBackground() {
    ctx.drawImage(imgBg, 0, 0, 3840, 2160); //Background
  }

  function draw() {
    drawBackground();

    for (var i = 0; i < noOfDrops; i++) {
      ctx.drawImage(fallingDrops[i].image, fallingDrops[i].x, fallingDrops[i].y); //The rain drop

      fallingDrops[i].y += fallingDrops[i].speed; //Set the falling speed
      if (fallingDrops[i].y > 1820) {
        //Repeat the raindrop when it falls out of view
        fallingDrops[i].y = Math.random() * -2000 + 1200; //Account for the image size
        fallingDrops[i].x = Math.random() * 4100 - 1200; //Make it appear randomly along the width
      }
    }
  }

  function setup() {
    var canvas = document.getElementById("canvas");

    if (canvas.getContext) {
      ctx = canvas.getContext("2d");
      ctx.canvas.width = window.innerWidth + 2400;
      ctx.canvas.height = window.innerHeight;
      imgBg = new Image();
      imgBg.src = "";
      setInterval(draw, 36);
      for (var i = 0; i < noOfDrops; i++) {
        var fallingDr = {};
        fallingDr["image"] = new Image();
        fallingDr.image.src = "https://s3.us-west-1.amazonaws.com/toastie.dev/resources/Noggin.png";
        fallingDr["x"] = Math.random() * 4100 - 1200;
        fallingDr["y"] = Math.random() * -2000;
        fallingDr["speed"] = 6 + Math.random() * 5;
        fallingDrops.push(fallingDr);
      }
    }
  }

  setup();
};
