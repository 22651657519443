import React from "react";
import { createRoot } from 'react-dom/client'
import App from "./App";
import "./index.css";
import { AprilFools } from "./components/AprilFools";

const currentYear = new Date().getFullYear();
const currentDate = new Date();
const start = new Date(`03/30/${currentYear}`);
const end = new Date(`04/01/${currentYear}`);

localStorage.getItem("gnomes") === null && localStorage.setItem("gnomes", "true");

if ((currentDate > start && currentDate < end) && localStorage.getItem("gnomes") === "true") {
    AprilFools();
}

const container = document.getElementById('app');
const root = createRoot(container);
root.render(<App tab="home" />);