import React, { useEffect, useState } from "react";
import "../index.css";
import { useInterval } from "../functions/functions.js";
import GuildHeader from "./GuildHeader.js";
import Report from "./Report.js";

export default function GuildRaidnight({ datePicked, guild, isPersonal, guildURL, api_key }) {
  const [reports, setReports] = useState([]);
  const [timerCount, setTimerCount] = useState(0);

  useInterval(() => {
    setTimerCount(timerCount + 1);
  }, 240000);

  useEffect(() => {
    async function fetchData() {
      var urlStart = new Date(datePicked).setMonth(datePicked.getMonth());
      // this is also ugly. Time for API hooks?
      const url = `https://www.warcraftlogs.com:443/v1/reports/${isPersonal ? "user" : "guild"}/${guildURL}/${isPersonal ? "" : "US"}?start=${urlStart}&api_key=${api_key}`;

      try {
        const result = await (await fetch(url)).json();
        if (!result.error) {
          setReports(result);
        } else {
          setReports(null);
        }
      } catch (err) {
        console.error("Fix this, error from fetching reports");
      }
    }
    fetchData();
  }, [datePicked, timerCount, guildURL, isPersonal, api_key]);

  if (!(datePicked instanceof Date)) return <div>Pick a date!</div>;
  try {
    const startTimestamp = datePicked.setHours(0).valueOf();
    const endTimestamp = new Date(datePicked).setDate(datePicked.getDate() + 1).valueOf();
    const selectedReports = reports.filter((report) => report.start >= startTimestamp && report.start < endTimestamp);
    if (selectedReports.length !== 0) {
      return (
        <React.Fragment>
          {isPersonal ? <React.Fragment></React.Fragment> : <GuildHeader guild={guild} url={guildURL} />}
          {selectedReports.reverse().map((report) => (
            <table className="tableReportSummary" key={report.id}>
              <Report guild={guild} {...report} api_key={api_key} />
            </table>
          ))}
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          {isPersonal ? <React.Fragment></React.Fragment> : <GuildHeader guild={guild} url={guildURL} />}
          <table className="tableReportSummary">
            <thead>
              <tr className="raidnightTableHeaderRow">
                <th className="whiteTextCenter" colSpan="6">
                  No logs... yet.
                </th>
              </tr>
            </thead>
          </table>
        </React.Fragment>
      );
    }
  } catch {
    return null;
  }
}
