import React from "react";

export default function CategoryButton({ setter, label, mode, currentMode }) {
  return (
    <button
      onClick={() => {
        setter(mode);
        localStorage.setItem("mode", mode);
      }}
      className={currentMode === `${mode}` ? "mode-select-button active" : "mode-select-button"}
    >
      {`${label}`}
    </button>
  );
}
