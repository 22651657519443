export default async function getLivestreamers(id) {
    const response = await fetch(`${window.rtApiUrl}/twitch/streamers`)

    if (!response.ok) {
        return Promise.resolve(null);
    }

    const data = await response.json();

    return Promise.resolve(data);
}