import React, { useEffect, useState } from "react";
import { guildRankColorizer, stripTitle } from "../functions/functions.js";
import world from '../SVG/world.svg'
import server from '../SVG/server.svg'
import flag from '../SVG/flag.svg'
import QuestionMark from '../SVG/question-mark.svg'
import getBestProg from "../queries/getBestProg.js";
import { useQuery } from "@tanstack/react-query";
import { bossHealth } from "../functions/functions.js";
import getConfig from "../queries/getConfig.js";


export default function GuildHeader({ guild, url }) {
  const { data } = useQuery({ queryKey: ['config'], queryFn: getConfig, refetchInterval: 600000, staleTime: 600000 });
  const [raiderIO, setraiderIO] = useState(0);
  const bestProg = useQuery({ queryKey: ["bestProg"], queryFn: getBestProg, refetchInterval: 60000 });
  let GuildProgressData = null;

  try {
    if (bestProg.isSuccess && bestProg.data && bestProg?.data[guild.replaceAll(' ', '_')]) {
      GuildProgressData = bestProg.data[guild.replaceAll(' ', '_')]
      GuildProgressData.splitPercent = Number(GuildProgressData.DisplayPercent.split('%')[0])
    }
  } catch (err) {
    console.log('problem with the backend, skipping best prog')
  }

  useEffect(() => {
    let realm = url.split("/")[1];
    async function fetchData() {
      let url = `https://raider.io/api/v1/guilds/profile?region=us&realm=${realm}&name=${guild}&fields=raid_progression,raid_rankings`;

      try {
        let result = await (await fetch(url)).json();
        if (result.raid_progression[Object.keys(result.raid_progression)[0]]) {
          setraiderIO(result);
        }
      } catch (err) {
        console.log(err);
      }
    }
    fetchData();
    return () => { };
  }, [url, guild]);

  if (raiderIO) {
    let raid_progression = raiderIO.raid_progression,
      raid_rankings = raiderIO.raid_rankings[data.tier_Slug]?.mythic || {
        world: "Flat",
        region: "Hi Sephurik",
        realm: "#1 in my heart",
      },
      summary = raid_progression[data.tier_Slug]?.summary || "";
    try {
      return (
        <table className="tableReportSummary">
          <thead>
            <tr className="raidnightTableHeaderRow guild-name-row">
              <td className="guild-name-row-child guild-name">
                <a href={raiderIO.profile_url}>{guild}</a>
                <span className="whiteTextCenter">{summary}</span>
              </td>
              <td className="guild-name-row-child guild-rankings">
                <img src={server} alt="Server" className="ranking-svg" />
                <span className={guildRankColorizer(raid_rankings.realm, "realm")} >{raid_rankings.realm}</span>
                <img src={flag} alt="flag representing country" className="ranking-svg" />
                <span className={guildRankColorizer(raid_rankings.region, "region")}>{raid_rankings.region}</span>
                <img src={world} alt="world" className="ranking-svg" />
                <span className={guildRankColorizer(raid_rankings.world, "region")}>{raid_rankings.world}</span>
              </td>
            </tr>
            {bestProg.isSuccess && GuildProgressData &&
              <tr className="raidnightTableHeaderRow">
                <td className={`guild-best-prog ${GuildProgressData.IsKilled ? 'liveOnTwitch' : ""}`}>
                  <span className='guild-best-prog-child'>{stripTitle(GuildProgressData.CurrBoss)}</span>
                  {GuildProgressData.IsKilled ?
                    <React.Fragment></React.Fragment>
                    :
                    <span className="guild-best-prog-child">Best: {bossHealth({ fightPercentage: GuildProgressData.splitPercent * 100, name: GuildProgressData.CurrBoss, addtlString: GuildProgressData.DisplayPercent.split('%')[1] })}</span>
                  }
                  <span className="guild-best-prog-child">Pulls: {GuildProgressData.PullCount}</span>
                  {GuildProgressData.PullCount === 0 && GuildProgressData.DisplayPercent === "100%" ?
                    <React.Fragment><img src={QuestionMark} alt="question mark" title="Guild May Have Hidden Their Prog" className="ranking-svg" /></React.Fragment>
                    :
                    <React.Fragment></React.Fragment>
                  }
                </td>
              </tr>
            }
          </thead>
        </table>
      );
    } catch (err) {
      console.error("ERROR WITH: " + guild);
      console.error(err);
      return (
        <tr>
          <th colSpan="6">
            <span className="legendary">{guild} (something went wrong)</span>
          </th>
        </tr>
      );
    }
  }
  return (
    <table className="tableReportSummary">
      <thead>
        <tr className="raidnightTableHeaderRow">
          <th colSpan="6">
            <span className="legendary">{guild}</span>
          </th>
        </tr>
      </thead>
    </table>
  );
}

